import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

type DisplayedMediaType = "NONE" | "IMAGE" | "PDF" | "UNSUPPORTED";

@Component({
  selector: 'app-file-selector-with-preview',
  templateUrl: './file-selector-with-preview.component.html',
  styleUrls: ['./file-selector-with-preview.component.scss'],
})
export class MediaPreviewComponent implements OnInit, OnDestroy {
  private static readonly defaultNoMediaText: string = "Válaszd ki vagy húzd ide a feltöltendő fájlt!";
  private static readonly defaultDragMediaText: string = "Húzd ide a feltöltendő fájlt!";

  @Input() fileFormControl: FormControl = new FormControl<File | null>(null);

  @Input() acceptableFileTypes: ReadonlyArray<string> = [];

  /** Event emitter to emit event if the form control's value is changed */
  @Output() valueChanges: EventEmitter<File> = new EventEmitter<File>();

  /** The (hidden) native input field */
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;


  /** If a file is selected, check if it has an acceptable type and update the form control's value */
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file: File | null = event && event.item(0);

    if (!file) {
      return;
    }

    if (this.isFileTypeAllowed(file)) {
      this.fileFormControl.setValue(file);
    } else {
      this.openErrorDialog("Nem elfogadott fájlformátum!");
    }
  }

  // On clicking the host component, open the file upload window
  @HostListener('click', ['$event']) onClick() {
    this.fileInput.nativeElement.click();
  }

  fileUrl = "";
  displayedMediaType: DisplayedMediaType = "NONE";
  noneText: string = MediaPreviewComponent.defaultNoMediaText;

  bodyEventHandlers: Map<string, EventListenerOrEventListenerObject> = new Map<string, EventListenerOrEventListenerObject>();

  dragCounter: number = 0;

  valueChangesSubscription: Subscription;

  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService:MatDialog,
    @Inject(DOCUMENT) private document: Document,
    ) { }

  ngOnInit(): void {
    this.addFileFormControllerListener();
    this.initDragEventHandling();
  }

  initDragEventHandling() {
    // Add the drop event handlers to the body
    const events = ['dragenter', 'dragover', 'dragleave', 'drop'] as const;
    events.forEach(eventName => {
      const handler: (event: Event) => void = this.getDragEventHandler(eventName);
      this.document.body.addEventListener(eventName, handler, false)
      this.bodyEventHandlers.set(eventName, handler);
    });

    // Add the drop event handler to the host element
    this.elementRef.nativeElement.addEventListener("drop", this.handleDropEvent, false);
  }

  addFileFormControllerListener() {
    this.valueChangesSubscription = this.fileFormControl.valueChanges.subscribe(
      (file: File) => {
        this.changeDetectorRef.markForCheck();
        if(file == null){
          this.displayedMediaType = 'NONE';
        }

        if (!(file instanceof File)) {
          return;
        }

        // Check if it's an image
        if (this.isImage(file)) {
          this.displayedMediaType = "IMAGE";

          // Check if it's a pdf
        } else if (this.isPdf(file)) {
          this.displayedMediaType = "PDF";

          // Otherwise it's unsupported
        } else {
          this.displayedMediaType = "UNSUPPORTED";
        }

        // Create an URL from the file to display it
        this.fileUrl = URL.createObjectURL(file);

        // Emit a value changes event
        this.valueChanges.emit(file);
      }
    );
  }


  ngOnDestroy(): void {
    this.valueChangesSubscription.unsubscribe();

    // Remove the drop handlers from the body
    this.bodyEventHandlers.forEach(
      (value: EventListenerOrEventListenerObject, key: string) => {
        this.document.body.removeEventListener(key, value, false);
      }
    );

    // Remove the drop event handler from the host element
    this.elementRef.nativeElement.removeEventListener("drop", this.handleDropEvent);
  }

  /**
   * Checks if a file is an image.
   *
   * @param file the file
   * @returns true if the file's MIME type is image
   */
  private isImage(file: File): boolean {
    return file.type.includes("image/");
  }

  /**
   * Checks if a file is a PDF.
   *
   * @param file the file
   * @returns true if the file's MIME type is pdf
   */
  private isPdf(file: File): boolean {
    return file.type === "application/pdf";
  }

  /**
   * Sanitize an unsafe URL
   *
   * @param unsafeUrl the unsafe URL
   * @returns the sanditized URL
   */
  public getSanitizedUrl(unsafeUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
  }

  /**
   * Handles the drop event.
   *
   * @param event the drop event
   */
  private handleDropEvent = (event: DragEvent) => {
    if (event.dataTransfer.files.length > 0) {
      // If a file or files dropped, check the first file and update the form control's value
      const file: File = event.dataTransfer.files.item(0);
      if (this.isFileTypeAllowed(file)) {
        this.fileFormControl.setValue(file);
      } else {
        this.openErrorDialog("Nem elfogadott fájlformátum!");
      }
    }
  };

  /**
   * Creates an event handler for the specified drag event.
   *
   * @param eventName the event name to create a handler for
   * @returns the event handler
   */
  private getDragEventHandler(eventName: 'dragenter' | 'dragover' | 'dragleave' | 'drop') {
    return (event: Event) => {
      event.stopPropagation();
      event.preventDefault();

      switch (eventName) {
        case "dragenter": ++this.dragCounter; break;
        case "dragleave": --this.dragCounter; break;
        case "drop": this.dragCounter = 0; break;
      }

      if (this.dragCounter > 0) {
        this.noneText = MediaPreviewComponent.defaultDragMediaText;
        this.elementRef.nativeElement.classList.add("background-animating");
      } else {
        this.noneText = MediaPreviewComponent.defaultNoMediaText;
        this.elementRef.nativeElement.classList.remove("background-animating");
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Checks whether the file type is acceptable.
   *
   * @param file the file
   * @returns boolean that indicates if the file is allowed
   */
  private isFileTypeAllowed(file: File): boolean {
    return this.acceptableFileTypes.includes(file.type);
  }

  /**
   * Opens an error dialog with the given message.
   *
   * @param message the message
   */
  private openErrorDialog(message:string):void {
    this.dialogService.open(
      ErrorDialogComponent,
      {
        data: {
          displayedText: message
        },
        disableClose: true,
        scrollStrategy: new NoopScrollStrategy(),
        hasBackdrop: true,
        backdropClass: 'invisible-backdrop'
      }
    );
  }

  /**
   * Resets the file selection state.
   * Call this method to clear the selected file and reset the component state.
   */
  public reset(): void {
    // Reset the form control value
    this.fileFormControl.setValue(null);
    this.fileFormControl.markAsUntouched();

    // Reset the UI state
    this.displayedMediaType = "NONE";
    this.noneText = MediaPreviewComponent.defaultNoMediaText;

    // Reset the file input element
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }

    // Reset URL if one was created
    if (this.fileUrl) {
      URL.revokeObjectURL(this.fileUrl);
      this.fileUrl = "";
    }

    // Force change detection
    this.changeDetectorRef.markForCheck();
  }

}
