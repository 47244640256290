import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";

export class CustomValidators {
  public static email(control:AbstractControl):ValidationErrors|null {
    const emailValidatorRegExp:RegExp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    return (Validators.pattern(emailValidatorRegExp))(control) ? { email: true } : null;
  }

  public static phoneNumber(control:AbstractControl):ValidationErrors|null {
    const phoneNumberRegexp:RegExp = new RegExp(/^[\+]?[0-9]{8,}$/);
    return (Validators.pattern(phoneNumberRegexp))(control) ? { phoneNumber: true } : null;
  }
}