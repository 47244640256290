import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InternetConnectionCheckerService } from './internet-connection-checker.service';
import { ResponseErrorHandlerService } from './response-error-handler.service';

export enum BackendServiceException {
  SERVER_NOT_AVAILABLE,
  NO_INTERNET_CONNECTION,
  NO_RESPONSE_BODY
}

export type ApiCallOptions<RequestBodyType, ResponseBodyType> = {
  httpRequestBody?: RequestBodyType,
  files?: ReadonlyMap<string, File>,
  callbacks?: HttpCallbacks<ResponseBodyType>,
  useDefaultErrorHandlers?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(
    private httpClient: HttpClient,
    private internetConnectionCheckerService: InternetConnectionCheckerService,
    private responseErrorHandlerService: ResponseErrorHandlerService
  ) { }

  // BackendServiceException kivételt dobhat
  public async callApi<RequestBodyType = Object, ResponseBodyType = Object>(
    apiUrlFragment: string,
    httpRequestMethod: HttpRequestMethod,
    apiCallOptions: ApiCallOptions<RequestBodyType, ResponseBodyType> = {
      useDefaultErrorHandlers: true
    }
  ): Promise<ResponseBodyType> {

    // Construct the HTTP request object
    const httpRequest: HttpRequest<RequestBodyType | FormData> = this.createHttpRequest(apiUrlFragment, httpRequestMethod, apiCallOptions);

    // Create the HTTP event observable with the HTTP request
    const httpEventObservable: Observable<HttpEvent<ResponseBodyType>> = this.httpClient.request<ResponseBodyType>(httpRequest);

    try {
      return await this.createHttpResponsePromise(httpEventObservable, apiCallOptions.callbacks);
    } catch (error: any) {
      if (apiCallOptions.useDefaultErrorHandlers) {
        this.responseErrorHandlerService.processResponse(error);
      }

      // Rethrow the error
      throw error;
    }
  }

  private createHttpRequest<RequestBodyType, ResponseBodyType>(
    apiUrlFragment: string,
    httpRequestMethod: HttpRequestMethod,
    apiCallOptions: ApiCallOptions<RequestBodyType, ResponseBodyType>
  ): HttpRequest<RequestBodyType | FormData> {
    // Construct the API URL
    const apiUrl: string = environment.serverAddress + apiUrlFragment;

    // Construct the HTTP options (headers, should report progress, etc.)
    let httpHeaders: HttpHeaders = new HttpHeaders();

    const reportProgress: boolean = apiCallOptions.callbacks?.onDownloadProgress != undefined || apiCallOptions.callbacks?.onUploadProgress != undefined;
    const httpOptions: HttpOptions = {
      headers: httpHeaders,
      responseType: "json",
      reportProgress: reportProgress
    }

    // Construct and return the HTTP request
    switch (httpRequestMethod) {
      case "DELETE":
      case "GET":
        return new HttpRequest<RequestBodyType>(httpRequestMethod, apiUrl, httpOptions);
      case "POST":
      case "PUT":
        if (apiCallOptions.files !== undefined) {
          const formData: FormData = new FormData();

          if (apiCallOptions.httpRequestBody) {
            formData.append("data", JSON.stringify(apiCallOptions.httpRequestBody));
          }

          for (const [fileName, file] of apiCallOptions.files) {
            formData.append(fileName, file);
          }

          return new HttpRequest<FormData>(httpRequestMethod, apiUrl, formData, httpOptions);
        } else {
          apiCallOptions.httpRequestBody ??= {} as RequestBodyType;
          return new HttpRequest<RequestBodyType>(httpRequestMethod, apiUrl, apiCallOptions.httpRequestBody, httpOptions);
        }

    }
  }

  private createHttpResponsePromise<ResponseBodyType>(
    httpEventObservable: Observable<HttpEvent<ResponseBodyType>>,
    callbacks?: HttpCallbacks<ResponseBodyType>
  ): Promise<ResponseBodyType> {
    return new Promise<ResponseBodyType>(
      (resolve: (value: ResponseBodyType | PromiseLike<ResponseBodyType>) => void, reject: (reason: any) => void) => {
        const httpEventSubscription: Subscription = httpEventObservable.subscribe(
          (httpEvent: HttpEvent<ResponseBodyType>) => {
            switch (httpEvent.type) {

              case HttpEventType.Sent:
                callbacks?.onSent?.();
                break;

              case HttpEventType.UploadProgress:
                callbacks?.onUploadProgress?.(httpEvent.loaded, httpEvent.total);
                break;

              case HttpEventType.ResponseHeader:
                callbacks?.onResponseHeader?.(httpEvent);
                break;

              case HttpEventType.DownloadProgress:
                callbacks?.onDownloadProgress?.(httpEvent.loaded, httpEvent.total);
                break;

              case HttpEventType.Response:
                switch (httpEvent.status) {
                  case 200:
                  case 201:
                  case 203:
                  case 204:
                  case 304:
                    const httpResponseBody: ResponseBodyType | null = httpEvent.body;
                    if (httpResponseBody === null) {
                      reject(BackendServiceException.NO_RESPONSE_BODY);
                      return;
                    }

                    resolve(httpResponseBody);
                    break;

                  default:
                    reject(httpEvent);
                }
                setTimeout(() => httpEventSubscription.unsubscribe(), 0);
                break;

              default:
              // HttpEventType.User falls here -- nothing to do
            }
          },
          async (httpResponseError: any) => {
            if (httpResponseError?.name === "TimeoutError") {
              const isThereInternetConnection: boolean = await this.internetConnectionCheckerService.hasInternetConnection();
              const exception: BackendServiceException = isThereInternetConnection ? BackendServiceException.SERVER_NOT_AVAILABLE : BackendServiceException.NO_INTERNET_CONNECTION;
              reject(exception);
              return;
            }

            reject(httpResponseError);
          }
        );
      }
    );
  }
}

export type HttpRequestMethod = "DELETE" | "GET" | "POST" | "PUT";

export class HttpCallbacks<ResponseBodyType = any> {
  onSent?: () => void;
  onUploadProgress?: (loaded: number, total?: number) => void;
  onResponseHeader?: (httpEvent: HttpEvent<ResponseBodyType>) => void;
  onDownloadProgress?: (loaded: number, total?: number) => void;
}

class HttpOptions {
  headers?: HttpHeaders;
  context?: HttpContext;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}
